<template>
  <div class="otherOrderDelivery">
    <p class="title">app版本管理</p>

    <div class="content">
      <div class="btnBox">
        <el-button type="primary" @click="openDialog(1)">新增记录</el-button>
      </div>
      <el-table :data="tableData" stripe height="640" v-loading="loading">
        <el-table-column
          prop="sys_id"
          width="50"
          label="标识"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sys_version"
          width="70"
          label="最新版本号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apple_id"
          width="120"
          label="主版本号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="update_info"
          width="50"
          label="更新大小"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="file_url"
          label="下载地址"
          align="center"
        ></el-table-column>
        <el-table-column
          label="更新内容"
          align="center"
        >
          <template slot-scope="scope">
              <p class="update_descCla">{{scope.row.update_desc}}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="openDialog(2,scope.row)"
            >
             修改
            </el-button>
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="confirmDelete(scope.row)"
            >
              <el-button slot="reference" size="small" type="danger" style="margin-left: 10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>

      <el-dialog
        :title="type==1?'新增':'修改'"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        >
        <div class="dialogCla">
          <p>标识：{{paramsData.sys_id}}</p>
          <p>版本号：</p>
          <el-input v-model="paramsData.sys_version" placeholder="请输入版本号"></el-input>
          <p>主版本号</p>
          <el-input v-model="paramsData.apple_id" placeholder="请输入主版本号"></el-input>
          <p>app下载地址：</p>
          <el-input v-model="paramsData.file_url" placeholder="请输入app下载地址"></el-input>
          <p>更新大小：</p>
          <el-input v-model="paramsData.update_info" placeholder="请输入更新大小"></el-input>
          <p>更新内容：</p>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="更新内容"
            v-model="paramsData.update_desc">
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template><script>
import apiDetail from '@/api/other.js'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      loading: false,
      tableData: [{}],
      dialogVisible: false,

      paramsData: {
        sys_id: '',
        sys_version: '',
        apple_id: '',
        file_url: '',
        update_info: '',
        update_desc: '',
      },

      

      type: 1,
      size: 10,
      total: 0,
      page: 1,
    }
  },
  mounted() {
    this.getty_sys_info_listFun()
  },
  methods: {
    // 订单发货列表
    getty_sys_info_listFun() {
      let param = {
        user_id: JSON.parse(localStorage.getItem("userInfo")).user_id,
        size: this.size,
        page: this.page,
      };
      apiDetail.getty_sys_info_list(param).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.count
      })
    },
    //  翻页
    handleCurrentChange(value) {
      this.page = value
      this.getty_sys_info_listFun()
    },

    // 打开详情弹框
    openDialog(type,value) {
      this.type = type;
      if(type == 1){
        
        Object.keys(this.paramsData).forEach(key => (this.paramsData[key] = ''));
      }else{
        this.paramsData = value
      }
      this.dialogVisible = true;
    },
    // 确认
    confirm() {
      let param = {...this.paramsData};
      if(this.type == 1){
        delete param.sys_id
        apiDetail.postty_sys_info_list(param).then((res) => {
          this.dialogVisible = false;
          Message.success('成功')
          })
      }else{
        apiDetail.putty_sys_info_list(param).then((res) => {
          this.dialogVisible = false;
          Message.success('成功')
          })
      }
    },
    // 删除
    confirmDelete(value) {
      let param = {sys_id:value.sys_id}
      apiDetail.deletety_sys_info_list(param).then((res) => {
        Message.success('成功')
        this.getty_sys_info_listFun()
        })
    }
  },
}
</script>
<style lang="scss" scoped>
.otherOrderDelivery {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
  .content {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    .btnBox {
      text-align: right;
      margin-bottom: 20px;
    }
  }
}
.update_descCla {
    white-space:pre-wrap; 
}

</style>